import React from "react"
import SEO from "../components/seo"
import './styles/button-example.scss';

const ButtonExample = () => (
    <>
    <SEO title="Button Example"/>
    <main>
        <div className="wrapper">
            <div onclick="doSomething()" class="pseudo-button">
            Click This
            </div>
        </div>
    </main>
  </>
)

export default ButtonExample